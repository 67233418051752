/**
 * "Convert a UTC date to a local date by adding the timezone offset to the UTC date and then
 * subtracting the timezone offset from the hours."
 *
 * The first line of the function creates a new date object by adding the timezone offset to the UTC
 * date.
 *
 * The second line of the function gets the timezone offset in hours.
 *
 * The third line of the function gets the hours of the UTC date.
 *
 * The fourth line of the function sets the hours of the new date object to the hours of the UTC date
 * minus the timezone offset.
 *
 * The fifth line of the function returns the new date object.
 * @param date - The date you want to convert
 * @returns A function that takes a date as an argument and returns a new date.
 */

function convertUTCDateToLocalDate(date, includeTime) {
    if (includeTime) {
        return new Date(date).toLocaleString('en-CA')
    }

    return new Date(date).toLocaleDateString('en-CA')
}

export default convertUTCDateToLocalDate
