<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${appSkinVariant}`"
  >
    <transition
      :name="appRouteTransition"
      mode="out-in"
      appear
    >
      <router-view></router-view>
    </transition>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'

// Layouts
import useDynamicVh from '@core/utils/useDynamicVh'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'

// import { useVisitStore } from '@/stores/Visit.js'
import { useVisitStore, useOrderStore } from '@/stores/index.js'

// Dynamic vh

export default {
    components: {
        LayoutContentVerticalNav,
        LayoutContentHorizontalNav,
        LayoutBlank,
    },
    setup() {
        const visitStore = useVisitStore()
        const orderStore = useOrderStore()
        const { route } = useRouter()
        const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

        const { handleBreakpointLayoutSwitch } = useLayout()
        handleBreakpointLayoutSwitch()

        const resolveLayoutVariant = computed(() => {
            if (route.value.meta.layout === 'blank') return 'layout-blank'
            if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

            return null
        })

        useDynamicVh()

        return {
            visitStore,
            orderStore,
            resolveLayoutVariant,
            appSkinVariant,
            appRouteTransition,
        }
    },

    //   watch: {
    //     visitStore: {
    //       handler(newVal) {
    //         console.log('Visit store changed', newVal)
    //       },
    //       deep: true,
    //     },
    //   },
    mounted() {
        this.getFromLocalStorage()
    },
    methods: {
        getFromLocalStorage() {
            const form = JSON.parse(localStorage.getItem('form'))
            if (form) {
                this.visitStore.setVisit({ id: form?.id || '', dob: form?.dob || '' })
            }
        },
    },
}
</script>

<style>
@font-face {
  font-family: 'Robertson';
  src: local('Robertson'), url(./assets/fonts/Robertson.ttf) format('truetype');
}
</style>
