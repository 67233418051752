/**
 * It takes a string, converts it to lowercase, splits it into an array of words, capitalizes the first
 * letter of each word, and then joins the array back into a string
 * @returns A function that takes a string and returns a string with the first letter of each word
 * capitalized.
 * @param  {} str
 */

const changeNameCase = str => {
    if (typeof str === 'string' || str instanceof String) {
        return str.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')
    }

    throw new Error('Argument requires a string')
}

export default changeNameCase
